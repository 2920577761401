<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    :loading="loading"
    submit-title="Yes"
    :title="title"
    width="520"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="$emit('confirm')"
  >
    <div class="mb-4 bold">
      <p v-if="message">
        {{ message }}
      </p>
      <slot name="main-slot" />
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
    message:{
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

