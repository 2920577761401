import { 
  NetherlandsAntillesIcon,
  CoteDivoireIcon,
  RepublicOfAbkhaziaIcon,
  ElandIslandsIcon,
  SouthOssetiaIcon,
  TransnistriaIcon,
  FederalRepublicOfAmbazoniaIcon,
  NagornoKarabakhIcon,
  EuropeanUnionIcon,

} from '@/pages/entity/models/contries-base-64-icons'

export const localCountriesList = [
  { name: "Federal Republic of Ambazonia", icon: FederalRepublicOfAmbazoniaIcon},
  { name: "Nagorno-Karabakh", icon: NagornoKarabakhIcon},
  { name: "European Union", icon: EuropeanUnionIcon},
  { name: "Cote d'Ivoire", icon: CoteDivoireIcon},
  { name: 'Republic of Abkhazia', icon: RepublicOfAbkhaziaIcon},
  { name: 'Eland Islands', icon: ElandIslandsIcon},
  { name: 'South Ossetia', icon: SouthOssetiaIcon},
  { name: 'Transnistria', icon: TransnistriaIcon},
  { name: 'Netherlands Antilles', icon: NetherlandsAntillesIcon},
  { name: 'French Southern and Antarctic Lands', icon: ''},
  { name: 'Paracel Islands', icon: ''},
  { name: 'Spratly Islands', icon: ''},
  { name: 'United States Virgin Islands', icon: ''},
  { name: 'Palestinian Territory', icon: ''},
  { name: 'Ontario', icon: ''},
  { name: 'Laos', icon: ''},
  { name: 'Crimea', icon: ''},
  { name: 'Donetsk', icon: ''},
  { name: 'Luhansk', icon: ''},
  { name: 'Kosovo', icon: ''},
  { name: 'Pridnestrovie', icon: ''},
]

export const countriesForRestrictedJurisdictions = [
  { name: 'FATF List', icon: '', link: 'https://www.fatf-gafi.org/en/countries/black-and-grey-lists.html'},
]

export const allCountriesListV2 = [
  {
      "icon": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
      "name": "Afghanistan"
  },
  {
      "icon": "https://flagcdn.com/ax.svg",
      "name": "Åland Islands"
  },
  {
      "icon": "https://flagcdn.com/al.svg",
      "name": "Albania"
  },
  {
      "icon": "https://flagcdn.com/dz.svg",
      "name": "Algeria"
  },
  {
      "icon": "https://flagcdn.com/as.svg",
      "name": "American Samoa"
  },
  {
      "icon": "https://flagcdn.com/ad.svg",
      "name": "Andorra"
  },
  {
      "icon": "https://flagcdn.com/ao.svg",
      "name": "Angola"
  },
  {
      "icon": "https://flagcdn.com/ai.svg",
      "name": "Anguilla"
  },
  {
      "icon": "https://flagcdn.com/aq.svg",
      "name": "Antarctica"
  },
  {
      "icon": "https://flagcdn.com/ag.svg",
      "name": "Antigua and Barbuda"
  },
  {
      "icon": "https://flagcdn.com/ar.svg",
      "name": "Argentina"
  },
  {
      "icon": "https://flagcdn.com/am.svg",
      "name": "Armenia"
  },
  {
      "icon": "https://flagcdn.com/aw.svg",
      "name": "Aruba"
  },
  {
      "icon": "https://flagcdn.com/au.svg",
      "name": "Australia"
  },
  {
      "icon": "https://flagcdn.com/at.svg",
      "name": "Austria"
  },
  {
      "icon": "https://flagcdn.com/az.svg",
      "name": "Azerbaijan"
  },
  {
      "icon": "https://flagcdn.com/bs.svg",
      "name": "Bahamas"
  },
  {
      "icon": "https://flagcdn.com/bh.svg",
      "name": "Bahrain"
  },
  {
      "icon": "https://flagcdn.com/bd.svg",
      "name": "Bangladesh"
  },
  {
      "icon": "https://flagcdn.com/bb.svg",
      "name": "Barbados"
  },
  {
      "icon": "https://flagcdn.com/by.svg",
      "name": "Belarus"
  },
  {
      "icon": "https://flagcdn.com/be.svg",
      "name": "Belgium"
  },
  {
      "icon": "https://flagcdn.com/bz.svg",
      "name": "Belize"
  },
  {
      "icon": "https://flagcdn.com/bj.svg",
      "name": "Benin"
  },
  {
      "icon": "https://flagcdn.com/bm.svg",
      "name": "Bermuda"
  },
  {
      "icon": "https://flagcdn.com/bt.svg",
      "name": "Bhutan"
  },
  {
      "icon": "https://flagcdn.com/bo.svg",
      "name": "Bolivia (Plurinational State of)"
  },
  {
      "icon": "https://flagcdn.com/bq.svg",
      "name": "Bonaire, Sint Eustatius and Saba"
  },
  {
      "icon": "https://flagcdn.com/ba.svg",
      "name": "Bosnia and Herzegovina"
  },
  {
      "icon": "https://flagcdn.com/bw.svg",
      "name": "Botswana"
  },
  {
      "icon": "https://flagcdn.com/bv.svg",
      "name": "Bouvet Island"
  },
  {
      "icon": "https://flagcdn.com/br.svg",
      "name": "Brazil"
  },
  {
      "icon": "https://flagcdn.com/io.svg",
      "name": "British Indian Ocean Territory"
  },
  {
      "icon": "https://flagcdn.com/um.svg",
      "name": "United States Minor Outlying Islands"
  },
  {
      "icon": "https://flagcdn.com/vg.svg",
      "name": "Virgin Islands (British)"
  },
  {
      "icon": "https://flagcdn.com/vi.svg",
      "name": "Virgin Islands (U.S.)"
  },
  {
      "icon": "https://flagcdn.com/bn.svg",
      "name": "Brunei Darussalam"
  },
  {
      "icon": "https://flagcdn.com/bg.svg",
      "name": "Bulgaria"
  },
  {
      "icon": "https://flagcdn.com/bf.svg",
      "name": "Burkina Faso"
  },
  {
      "icon": "https://flagcdn.com/bi.svg",
      "name": "Burundi"
  },
  {
      "icon": "https://flagcdn.com/kh.svg",
      "name": "Cambodia"
  },
  {
      "icon": "https://flagcdn.com/cm.svg",
      "name": "Cameroon"
  },
  {
      "icon": "https://flagcdn.com/ca.svg",
      "name": "Canada"
  },
  {
      "icon": "https://flagcdn.com/cv.svg",
      "name": "Cabo Verde"
  },
  {
      "icon": "https://flagcdn.com/ky.svg",
      "name": "Cayman Islands"
  },
  {
      "icon": "https://flagcdn.com/cf.svg",
      "name": "Central African Republic"
  },
  {
      "icon": "https://flagcdn.com/td.svg",
      "name": "Chad"
  },
  {
      "icon": "https://flagcdn.com/cl.svg",
      "name": "Chile"
  },
  {
      "icon": "https://flagcdn.com/cn.svg",
      "name": "China"
  },
  {
      "icon": "https://flagcdn.com/cx.svg",
      "name": "Christmas Island"
  },
  {
      "icon": "https://flagcdn.com/cc.svg",
      "name": "Cocos (Keeling) Islands"
  },
  {
      "icon": "https://flagcdn.com/co.svg",
      "name": "Colombia"
  },
  {
      "icon": "https://flagcdn.com/km.svg",
      "name": "Comoros"
  },
  {
      "icon": "https://flagcdn.com/cg.svg",
      "name": "Congo"
  },
  {
      "icon": "https://flagcdn.com/cd.svg",
      "name": "Congo (Democratic Republic of the)"
  },
  {
      "icon": "https://flagcdn.com/ck.svg",
      "name": "Cook Islands"
  },
  {
      "icon": "https://flagcdn.com/cr.svg",
      "name": "Costa Rica"
  },
  {
      "icon": "https://flagcdn.com/hr.svg",
      "name": "Croatia"
  },
  {
      "icon": "https://flagcdn.com/cu.svg",
      "name": "Cuba"
  },
  {
      "icon": "https://flagcdn.com/cw.svg",
      "name": "Curaçao"
  },
  {
      "icon": "https://flagcdn.com/cy.svg",
      "name": "Cyprus"
  },
  {
      "icon": "https://flagcdn.com/cz.svg",
      "name": "Czech Republic"
  },
  {
      "icon": "https://flagcdn.com/dk.svg",
      "name": "Denmark"
  },
  {
      "icon": "https://flagcdn.com/dj.svg",
      "name": "Djibouti"
  },
  {
      "icon": "https://flagcdn.com/dm.svg",
      "name": "Dominica"
  },
  {
      "icon": "https://flagcdn.com/do.svg",
      "name": "Dominican Republic"
  },
  {
      "icon": "https://flagcdn.com/ec.svg",
      "name": "Ecuador"
  },
  {
      "icon": "https://flagcdn.com/eg.svg",
      "name": "Egypt"
  },
  {
      "icon": "https://flagcdn.com/sv.svg",
      "name": "El Salvador"
  },
  {
      "icon": "https://flagcdn.com/gq.svg",
      "name": "Equatorial Guinea"
  },
  {
      "icon": "https://flagcdn.com/er.svg",
      "name": "Eritrea"
  },
  {
      "icon": "https://flagcdn.com/ee.svg",
      "name": "Estonia"
  },
  {
      "icon": "https://flagcdn.com/et.svg",
      "name": "Ethiopia"
  },
  {
      "icon": "https://flagcdn.com/fk.svg",
      "name": "Falkland Islands (Malvinas)"
  },
  {
      "icon": "https://flagcdn.com/fo.svg",
      "name": "Faroe Islands"
  },
  {
      "icon": "https://flagcdn.com/fj.svg",
      "name": "Fiji"
  },
  {
      "icon": "https://flagcdn.com/fi.svg",
      "name": "Finland"
  },
  {
      "icon": "https://flagcdn.com/fr.svg",
      "name": "France"
  },
  {
      "icon": "https://flagcdn.com/gf.svg",
      "name": "French Guiana"
  },
  {
      "icon": "https://flagcdn.com/pf.svg",
      "name": "French Polynesia"
  },
  {
      "icon": "https://flagcdn.com/tf.svg",
      "name": "French Southern Territories"
  },
  {
      "icon": "https://flagcdn.com/ga.svg",
      "name": "Gabon"
  },
  {
      "icon": "https://flagcdn.com/gm.svg",
      "name": "Gambia"
  },
  {
      "icon": "https://flagcdn.com/ge.svg",
      "name": "Georgia"
  },
  {
      "icon": "https://flagcdn.com/de.svg",
      "name": "Germany"
  },
  {
      "icon": "https://flagcdn.com/gh.svg",
      "name": "Ghana"
  },
  {
      "icon": "https://flagcdn.com/gi.svg",
      "name": "Gibraltar"
  },
  {
      "icon": "https://flagcdn.com/gr.svg",
      "name": "Greece"
  },
  {
      "icon": "https://flagcdn.com/gl.svg",
      "name": "Greenland"
  },
  {
      "icon": "https://flagcdn.com/gd.svg",
      "name": "Grenada"
  },
  {
      "icon": "https://flagcdn.com/gp.svg",
      "name": "Guadeloupe"
  },
  {
      "icon": "https://flagcdn.com/gu.svg",
      "name": "Guam"
  },
  {
      "icon": "https://flagcdn.com/gt.svg",
      "name": "Guatemala"
  },
  {
      "icon": "https://flagcdn.com/gg.svg",
      "name": "Guernsey"
  },
  {
      "icon": "https://flagcdn.com/gn.svg",
      "name": "Guinea"
  },
  {
      "icon": "https://flagcdn.com/gw.svg",
      "name": "Guinea-Bissau"
  },
  {
      "icon": "https://flagcdn.com/gy.svg",
      "name": "Guyana"
  },
  {
      "icon": "https://flagcdn.com/ht.svg",
      "name": "Haiti"
  },
  {
      "icon": "https://flagcdn.com/hm.svg",
      "name": "Heard Island and McDonald Islands"
  },
  {
      "icon": "https://flagcdn.com/va.svg",
      "name": "Vatican City"
  },
  {
      "icon": "https://flagcdn.com/hn.svg",
      "name": "Honduras"
  },
  {
      "icon": "https://flagcdn.com/hu.svg",
      "name": "Hungary"
  },
  {
      "icon": "https://flagcdn.com/hk.svg",
      "name": "Hong Kong"
  },
  {
      "icon": "https://flagcdn.com/is.svg",
      "name": "Iceland"
  },
  {
      "icon": "https://flagcdn.com/in.svg",
      "name": "India"
  },
  {
      "icon": "https://flagcdn.com/id.svg",
      "name": "Indonesia"
  },
  {
      "icon": "https://flagcdn.com/ci.svg",
      "name": "Ivory Coast"
  },
  {
      "icon": "https://flagcdn.com/ir.svg",
      "name": "Iran (Islamic Republic of)"
  },
  {
      "icon": "https://flagcdn.com/iq.svg",
      "name": "Iraq"
  },
  {
      "icon": "https://flagcdn.com/ie.svg",
      "name": "Ireland"
  },
  {
      "icon": "https://flagcdn.com/im.svg",
      "name": "Isle of Man"
  },
  {
      "icon": "https://flagcdn.com/il.svg",
      "name": "Israel"
  },
  {
      "icon": "https://flagcdn.com/it.svg",
      "name": "Italy"
  },
  {
      "icon": "https://flagcdn.com/jm.svg",
      "name": "Jamaica"
  },
  {
      "icon": "https://flagcdn.com/jp.svg",
      "name": "Japan"
  },
  {
      "icon": "https://flagcdn.com/je.svg",
      "name": "Jersey"
  },
  {
      "icon": "https://flagcdn.com/jo.svg",
      "name": "Jordan"
  },
  {
      "icon": "https://flagcdn.com/kz.svg",
      "name": "Kazakhstan"
  },
  {
      "icon": "https://flagcdn.com/ke.svg",
      "name": "Kenya"
  },
  {
      "icon": "https://flagcdn.com/ki.svg",
      "name": "Kiribati"
  },
  {
      "icon": "https://flagcdn.com/kw.svg",
      "name": "Kuwait"
  },
  {
      "icon": "https://flagcdn.com/kg.svg",
      "name": "Kyrgyzstan"
  },
  {
      "icon": "https://flagcdn.com/la.svg",
      "name": "Lao People's Democratic Republic"
  },
  {
      "icon": "https://flagcdn.com/lv.svg",
      "name": "Latvia"
  },
  {
      "icon": "https://flagcdn.com/lb.svg",
      "name": "Lebanon"
  },
  {
      "icon": "https://flagcdn.com/ls.svg",
      "name": "Lesotho"
  },
  {
      "icon": "https://flagcdn.com/lr.svg",
      "name": "Liberia"
  },
  {
      "icon": "https://flagcdn.com/ly.svg",
      "name": "Libya"
  },
  {
      "icon": "https://flagcdn.com/li.svg",
      "name": "Liechtenstein"
  },
  {
      "icon": "https://flagcdn.com/lt.svg",
      "name": "Lithuania"
  },
  {
      "icon": "https://flagcdn.com/lu.svg",
      "name": "Luxembourg"
  },
  {
      "icon": "https://flagcdn.com/mo.svg",
      "name": "Macao"
  },
  {
      "icon": "https://flagcdn.com/mk.svg",
      "name": "North Macedonia"
  },
  {
      "icon": "https://flagcdn.com/mg.svg",
      "name": "Madagascar"
  },
  {
      "icon": "https://flagcdn.com/mw.svg",
      "name": "Malawi"
  },
  {
      "icon": "https://flagcdn.com/my.svg",
      "name": "Malaysia"
  },
  {
      "icon": "https://flagcdn.com/mv.svg",
      "name": "Maldives"
  },
  {
      "icon": "https://flagcdn.com/ml.svg",
      "name": "Mali"
  },
  {
      "icon": "https://flagcdn.com/mt.svg",
      "name": "Malta"
  },
  {
      "icon": "https://flagcdn.com/mh.svg",
      "name": "Marshall Islands"
  },
  {
      "icon": "https://flagcdn.com/mq.svg",
      "name": "Martinique"
  },
  {
      "icon": "https://flagcdn.com/mr.svg",
      "name": "Mauritania"
  },
  {
      "icon": "https://flagcdn.com/mu.svg",
      "name": "Mauritius"
  },
  {
      "icon": "https://flagcdn.com/yt.svg",
      "name": "Mayotte"
  },
  {
      "icon": "https://flagcdn.com/mx.svg",
      "name": "Mexico"
  },
  {
      "icon": "https://flagcdn.com/fm.svg",
      "name": "Micronesia (Federated States of)"
  },
  {
      "icon": "https://flagcdn.com/md.svg",
      "name": "Moldova (Republic of)"
  },
  {
      "icon": "https://flagcdn.com/mc.svg",
      "name": "Monaco"
  },
  {
      "icon": "https://flagcdn.com/mn.svg",
      "name": "Mongolia"
  },
  {
      "icon": "https://flagcdn.com/me.svg",
      "name": "Montenegro"
  },
  {
      "icon": "https://flagcdn.com/ms.svg",
      "name": "Montserrat"
  },
  {
      "icon": "https://flagcdn.com/ma.svg",
      "name": "Morocco"
  },
  {
      "icon": "https://flagcdn.com/mz.svg",
      "name": "Mozambique"
  },
  {
      "icon": "https://flagcdn.com/mm.svg",
      "name": "Myanmar"
  },
  {
      "icon": "https://flagcdn.com/na.svg",
      "name": "Namibia"
  },
  {
      "icon": "https://flagcdn.com/nr.svg",
      "name": "Nauru"
  },
  {
      "icon": "https://flagcdn.com/np.svg",
      "name": "Nepal"
  },
  {
      "icon": "https://flagcdn.com/nl.svg",
      "name": "Netherlands"
  },
  {
      "icon": "https://flagcdn.com/nc.svg",
      "name": "New Caledonia"
  },
  {
      "icon": "https://flagcdn.com/nz.svg",
      "name": "New Zealand"
  },
  {
      "icon": "https://flagcdn.com/ni.svg",
      "name": "Nicaragua"
  },
  {
      "icon": "https://flagcdn.com/ne.svg",
      "name": "Niger"
  },
  {
      "icon": "https://flagcdn.com/ng.svg",
      "name": "Nigeria"
  },
  {
      "icon": "https://flagcdn.com/nu.svg",
      "name": "Niue"
  },
  {
      "icon": "https://flagcdn.com/nf.svg",
      "name": "Norfolk Island"
  },
  {
      "icon": "https://flagcdn.com/kp.svg",
      "name": "Korea (Democratic People's Republic of)"
  },
  {
      "icon": "https://flagcdn.com/mp.svg",
      "name": "Northern Mariana Islands"
  },
  {
      "icon": "https://flagcdn.com/no.svg",
      "name": "Norway"
  },
  {
      "icon": "https://flagcdn.com/om.svg",
      "name": "Oman"
  },
  {
      "icon": "https://flagcdn.com/pk.svg",
      "name": "Pakistan"
  },
  {
      "icon": "https://flagcdn.com/pw.svg",
      "name": "Palau"
  },
  {
      "icon": "https://flagcdn.com/ps.svg",
      "name": "Palestine, State of"
  },
  {
      "icon": "https://flagcdn.com/pa.svg",
      "name": "Panama"
  },
  {
      "icon": "https://flagcdn.com/pg.svg",
      "name": "Papua New Guinea"
  },
  {
      "icon": "https://flagcdn.com/py.svg",
      "name": "Paraguay"
  },
  {
      "icon": "https://flagcdn.com/pe.svg",
      "name": "Peru"
  },
  {
      "icon": "https://flagcdn.com/ph.svg",
      "name": "Philippines"
  },
  {
      "icon": "https://flagcdn.com/pn.svg",
      "name": "Pitcairn"
  },
  {
      "icon": "https://flagcdn.com/pl.svg",
      "name": "Poland"
  },
  {
      "icon": "https://flagcdn.com/pt.svg",
      "name": "Portugal"
  },
  {
      "icon": "https://flagcdn.com/pr.svg",
      "name": "Puerto Rico"
  },
  {
      "icon": "https://flagcdn.com/qa.svg",
      "name": "Qatar"
  },
  {
      "icon": "https://flagcdn.com/xk.svg",
      "name": "Republic of Kosovo"
  },
  {
      "icon": "https://flagcdn.com/re.svg",
      "name": "Réunion"
  },
  {
      "icon": "https://flagcdn.com/ro.svg",
      "name": "Romania"
  },
  {
      "icon": "https://flagcdn.com/ru.svg",
      "name": "Russian Federation"
  },
  {
      "icon": "https://flagcdn.com/rw.svg",
      "name": "Rwanda"
  },
  {
      "icon": "https://flagcdn.com/bl.svg",
      "name": "Saint Barthélemy"
  },
  {
      "icon": "https://flagcdn.com/sh.svg",
      "name": "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
      "icon": "https://flagcdn.com/kn.svg",
      "name": "Saint Kitts and Nevis"
  },
  {
      "icon": "https://flagcdn.com/lc.svg",
      "name": "Saint Lucia"
  },
  {
      "icon": "https://flagcdn.com/mf.svg",
      "name": "Saint Martin (French part)"
  },
  {
      "icon": "https://flagcdn.com/pm.svg",
      "name": "Saint Pierre and Miquelon"
  },
  {
      "icon": "https://flagcdn.com/vc.svg",
      "name": "Saint Vincent and the Grenadines"
  },
  {
      "icon": "https://flagcdn.com/ws.svg",
      "name": "Samoa"
  },
  {
      "icon": "https://flagcdn.com/sm.svg",
      "name": "San Marino"
  },
  {
      "icon": "https://flagcdn.com/st.svg",
      "name": "Sao Tome and Principe"
  },
  {
      "icon": "https://flagcdn.com/sa.svg",
      "name": "Saudi Arabia"
  },
  {
      "icon": "https://flagcdn.com/sn.svg",
      "name": "Senegal"
  },
  {
      "icon": "https://flagcdn.com/rs.svg",
      "name": "Serbia"
  },
  {
      "icon": "https://flagcdn.com/sc.svg",
      "name": "Seychelles"
  },
  {
      "icon": "https://flagcdn.com/sl.svg",
      "name": "Sierra Leone"
  },
  {
      "icon": "https://flagcdn.com/sg.svg",
      "name": "Singapore"
  },
  {
      "icon": "https://flagcdn.com/sx.svg",
      "name": "Sint Maarten (Dutch part)"
  },
  {
      "icon": "https://flagcdn.com/sk.svg",
      "name": "Slovakia"
  },
  {
      "icon": "https://flagcdn.com/si.svg",
      "name": "Slovenia"
  },
  {
      "icon": "https://flagcdn.com/sb.svg",
      "name": "Solomon Islands"
  },
  {
      "icon": "https://flagcdn.com/so.svg",
      "name": "Somalia"
  },
  {
      "icon": "https://flagcdn.com/za.svg",
      "name": "South Africa"
  },
  {
      "icon": "https://flagcdn.com/gs.svg",
      "name": "South Georgia and the South Sandwich Islands"
  },
  {
      "icon": "https://flagcdn.com/kr.svg",
      "name": "Korea (Republic of)"
  },
  {
      "icon": "https://flagcdn.com/es.svg",
      "name": "Spain"
  },
  {
      "icon": "https://flagcdn.com/lk.svg",
      "name": "Sri Lanka"
  },
  {
      "icon": "https://flagcdn.com/sd.svg",
      "name": "Sudan"
  },
  {
      "icon": "https://flagcdn.com/ss.svg",
      "name": "South Sudan"
  },
  {
      "icon": "https://flagcdn.com/sr.svg",
      "name": "Suriname"
  },
  {
      "icon": "https://flagcdn.com/sj.svg",
      "name": "Svalbard and Jan Mayen"
  },
  {
      "icon": "https://flagcdn.com/sz.svg",
      "name": "Swaziland"
  },
  {
      "icon": "https://flagcdn.com/se.svg",
      "name": "Sweden"
  },
  {
      "icon": "https://flagcdn.com/ch.svg",
      "name": "Switzerland"
  },
  {
      "icon": "https://flagcdn.com/sy.svg",
      "name": "Syrian Arab Republic"
  },
  {
      "icon": "https://flagcdn.com/tw.svg",
      "name": "Taiwan"
  },
  {
      "icon": "https://flagcdn.com/tj.svg",
      "name": "Tajikistan"
  },
  {
      "icon": "https://flagcdn.com/tz.svg",
      "name": "Tanzania, United Republic of"
  },
  {
      "icon": "https://flagcdn.com/th.svg",
      "name": "Thailand"
  },
  {
      "icon": "https://flagcdn.com/tl.svg",
      "name": "Timor-Leste"
  },
  {
      "icon": "https://flagcdn.com/tg.svg",
      "name": "Togo"
  },
  {
      "icon": "https://flagcdn.com/tk.svg",
      "name": "Tokelau"
  },
  {
      "icon": "https://flagcdn.com/to.svg",
      "name": "Tonga"
  },
  {
      "icon": "https://flagcdn.com/tt.svg",
      "name": "Trinidad and Tobago"
  },
  {
      "icon": "https://flagcdn.com/tn.svg",
      "name": "Tunisia"
  },
  {
      "icon": "https://flagcdn.com/tr.svg",
      "name": "Turkey"
  },
  {
      "icon": "https://flagcdn.com/tm.svg",
      "name": "Turkmenistan"
  },
  {
      "icon": "https://flagcdn.com/tc.svg",
      "name": "Turks and Caicos Islands"
  },
  {
      "icon": "https://flagcdn.com/tv.svg",
      "name": "Tuvalu"
  },
  {
      "icon": "https://flagcdn.com/ug.svg",
      "name": "Uganda"
  },
  {
      "icon": "https://flagcdn.com/ua.svg",
      "name": "Ukraine"
  },
  {
      "icon": "https://flagcdn.com/ae.svg",
      "name": "United Arab Emirates"
  },
  {
      "icon": "https://flagcdn.com/gb.svg",
      "name": "United Kingdom of Great Britain and Northern Ireland"
  },
  {
      "icon": "https://flagcdn.com/us.svg",
      "name": "United States of America"
  },
  {
      "icon": "https://flagcdn.com/uy.svg",
      "name": "Uruguay"
  },
  {
      "icon": "https://flagcdn.com/uz.svg",
      "name": "Uzbekistan"
  },
  {
      "icon": "https://flagcdn.com/vu.svg",
      "name": "Vanuatu"
  },
  {
      "icon": "https://flagcdn.com/ve.svg",
      "name": "Venezuela (Bolivarian Republic of)"
  },
  {
      "icon": "https://flagcdn.com/vn.svg",
      "name": "Vietnam"
  },
  {
      "icon": "https://flagcdn.com/wf.svg",
      "name": "Wallis and Futuna"
  },
  {
      "icon": "https://flagcdn.com/eh.svg",
      "name": "Western Sahara"
  },
  {
      "icon": "https://flagcdn.com/ye.svg",
      "name": "Yemen"
  },
  {
      "icon": "https://flagcdn.com/zm.svg",
      "name": "Zambia"
  },
  {
      "icon": "https://flagcdn.com/zw.svg",
      "name": "Zimbabwe"
  }
]
export const allCountriesListV3 = [
  {
      "name": "Afrikaans"
  },
  {
      "name": "Albanian"
  },
  {
      "name": "Amharic"
  },
  {
      "name": "Arabic"
  },
  {
      "name": "Aramaic"
  },
  {
      "name": "Armenian"
  },
  {
      "name": "Aymara"
  },
  {
      "name": "Azerbaijani"
  },
  {
      "name": "Basque"
  },
  {
      "name": "Belarusian"
  },
  {
      "name": "Belizean Creole"
  },
  {
      "name": "Bengali"
  },
  {
      "name": "Berber"
  },
  {
      "name": "Bislama"
  },
  {
      "name": "Bosnian"
  },
  {
      "name": "Bulgarian"
  },
  {
      "name": "Burmese"
  },
  {
      "name": "Carolinian"
  },
  {
      "name": "Catalan"
  },
  {
      "name": "Chamorro"
  },
  {
      "name": "Chewa"
  },
  {
      "name": "Chibarwe"
  },
  {
      "name": "Chinese"
  },
  {
      "name": "Comorian"
  },
  {
      "name": "Cook Islands Māori"
  },
  {
      "name": "Croatian"
  },
  {
      "name": "Czech"
  },
  {
      "name": "Danish"
  },
  {
      "name": "Dari"
  },
  {
      "name": "Dutch"
  },
  {
      "name": "Dzongkha"
  },
  {
      "name": "English"
  },
  {
      "name": "Estonian"
  },
  {
      "name": "Faroese"
  },
  {
      "name": "Fiji Hindi"
  },
  {
      "name": "Fijian"
  },
  {
      "name": "Filipino"
  },
  {
      "name": "Finnish"
  },
  {
      "name": "French"
  },
  {
      "name": "Galician"
  },
  {
      "name": "Georgian"
  },
  {
      "name": "German"
  },
  {
      "name": "Gilbertese"
  },
  {
      "name": "Greek"
  },
  {
      "name": "Greenlandic"
  },
  {
      "name": "Guaraní"
  },
  {
      "name": "Guernésiais"
  },
  {
      "name": "Haitian Creole"
  },
  {
      "name": "Hassaniya"
  },
  {
      "name": "Hebrew"
  },
  {
      "name": "Herero"
  },
  {
      "name": "Hindi"
  },
  {
      "name": "Hiri Motu"
  },
  {
      "name": "Hungarian"
  },
  {
      "name": "Icelandic"
  },
  {
      "name": "Indonesian"
  },
  {
      "name": "Irish"
  },
  {
      "name": "Italian"
  },
  {
      "name": "Jamaican Patois"
  },
  {
      "name": "Japanese"
  },
  {
      "name": "Jèrriais"
  },
  {
      "name": "Kalanga"
  },
  {
      "name": "Kazakh"
  },
  {
      "name": "Khmer"
  },
  {
      "name": "Khoekhoe"
  },
  {
      "name": "Khoisan"
  },
  {
      "name": "Kikongo"
  },
  {
      "name": "Kinyarwanda"
  },
  {
      "name": "Kirundi"
  },
  {
      "name": "Korean"
  },
  {
      "name": "Kwangali"
  },
  {
      "name": "Kyrgyz"
  },
  {
      "name": "Lao"
  },
  {
      "name": "Latin"
  },
  {
      "name": "Latvian"
  },
  {
      "name": "Lingala"
  },
  {
      "name": "Lithuanian"
  },
  {
      "name": "Lozi"
  },
  {
      "name": "Luxembourgish"
  },
  {
      "name": "Macedonian"
  },
  {
      "name": "Malagasy"
  },
  {
      "name": "Malay"
  },
  {
      "name": "Maldivian"
  },
  {
      "name": "Maltese"
  },
  {
      "name": "Manx"
  },
  {
      "name": "Māori"
  },
  {
      "name": "Marshallese"
  },
  {
      "name": "Mauritian Creole"
  },
  {
      "name": "Mongolian"
  },
  {
      "name": "Montenegrin"
  },
  {
      "name": "Nauru"
  },
  {
      "name": "Ndau"
  },
  {
      "name": "Ndonga"
  },
  {
      "name": "Nepali"
  },
  {
      "name": "New Zealand Sign Language"
  },
  {
      "name": "Niuean"
  },
  {
      "name": "Norfuk"
  },
  {
      "name": "Northern Ndebele"
  },
  {
      "name": "Northern Sotho"
  },
  {
      "name": "Norwegian"
  },
  {
      "name": "Norwegian Bokmål"
  },
  {
      "name": "Norwegian Nynorsk"
  },
  {
      "name": "Palauan"
  },
  {
      "name": "Papiamento"
  },
  {
      "name": "Pashto"
  },
  {
      "name": "Persian (Farsi)"
  },
  {
      "name": "Polish"
  },
  {
      "name": "Portuguese"
  },
  {
      "name": "Quechua"
  },
  {
      "name": "Romanian"
  },
  {
      "name": "Romansh"
  },
  {
      "name": "Russian"
  },
  {
      "name": "Sami"
  },
  {
      "name": "Samoan"
  },
  {
      "name": "Sango"
  },
  {
      "name": "Serbian"
  },
  {
      "name": "Seychellois Creole"
  },
  {
      "name": "Shona"
  },
  {
      "name": "Sinhala"
  },
  {
      "name": "Slovak"
  },
  {
      "name": "Slovene"
  },
  {
      "name": "Somali"
  },
  {
      "name": "Sorani"
  },
  {
      "name": "Sotho"
  },
  {
      "name": "Southern Ndebele"
  },
  {
      "name": "Southern Sotho"
  },
  {
      "name": "Spanish"
  },
  {
      "name": "Swahili"
  },
  {
      "name": "Swazi"
  },
  {
      "name": "Swedish"
  },
  {
      "name": "Swiss German"
  },
  {
      "name": "Tajik"
  },
  {
      "name": "Tamil"
  },
  {
      "name": "Tetum"
  },
  {
      "name": "Thai"
  },
  {
      "name": "Tigrinya"
  },
  {
      "name": "Tok Pisin"
  },
  {
      "name": "Tokelauan"
  },
  {
      "name": "Tonga"
  },
  {
      "name": "Tongan"
  },
  {
      "name": "Tshiluba"
  },
  {
      "name": "Tsonga"
  },
  {
      "name": "Tswana"
  },
  {
      "name": "Turkish"
  },
  {
      "name": "Turkmen"
  },
  {
      "name": "Tuvaluan"
  },
  {
      "name": "Ukrainian"
  },
  {
      "name": "Upper Guinea Creole"
  },
  {
      "name": "Urdu"
  },
  {
      "name": "Uzbek"
  },
  {
      "name": "Venda"
  },
  {
      "name": "Vietnamese"
  },
  {
      "name": "Xhosa"
  },
  {
      "name": "Zimbabwean Sign Language"
  },
  {
      "name": "Zulu"
  }
]
